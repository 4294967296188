import React from "react"
import ContactSection from "../../components/common/ContactSection"
import { contactSection } from "../../content/Common"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { properties } from "../../content/Properties"
import InnerPropertyPage from "../../components/molecules/InnerPropertyPage"

const slug = `38a-malone-si-morphett-vale-south-australia-5162`

const post = properties.find(post => post.slug === slug) // Find the blog post by slug

const AMaloneSiMorphettValeSouthAustralia5162 = () => (
  <Layout>
    <Seo
      title="Find Your Dream Home: NDIS Supported SDA Housing Vacancy in Christie Downs"
      description="Find ideal SDA housing in Christie Downs with NDIS support. A secure, comfortable option for those with special needs—enquire now to start living your best life!"
    />

    <InnerPropertyPage post={post} />

    <ContactSection data={contactSection} sectionTitleAlignment={"center"} />
  </Layout>
)

export default AMaloneSiMorphettValeSouthAustralia5162
